import * as React from 'react';

function IconInfo(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.667 9a8.333 8.333 0 1116.666 0A8.333 8.333 0 01.667 9zM9 15.667A6.667 6.667 0 119 2.333a6.667 6.667 0 010 13.334zM9 4.043a.833.833 0 100 1.666.833.833 0 000-1.666zM8.167 8.15a.833.833 0 111.666 0v5a.833.833 0 01-1.666 0v-5z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoIconInfo = React.memo(IconInfo);
export default MemoIconInfo;
