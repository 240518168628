import * as React from 'react';

import cl from 'classnames';
import { motion } from 'framer-motion';
import { get } from 'lodash';
import { getDocument } from 'ssr-window';
import styled from 'styled-components';

import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';

import IconInfo from '../icons/IconInfo';
import IconSmile from '../icons/IconSmile';

/* eslint-disable react/prop-types */
const MainTabs = styled.div`
  position: relative;
  border-bottom: 1px solid ${colorsV2.gray40};
  .tab-header {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, max-content));
    grid-auto-flow: column;
    gap: 32px;
    .tab {
      cursor: pointer;
      padding-bottom: 24px;
    }
  }
  .tab-header-text {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, max-content));
    grid-auto-flow: column;
    gap: 10px;
    align-items: center;
  }
  .tab-indicator {
    height: 4px;
    background-color: ${colorsV2.black100};
    position: absolute;
    bottom: 0;
  }
`;

export const Tab = styled.div``;

const TabIcon = ({ icon }) => {
  switch (icon) {
    case 'info': {
      return <IconInfo />;
    }
    case 'smile': {
      return <IconSmile />;
    }
  }
  return null;
};

const calculatePosition = ({ data, setTabElmPosition, frameDocument }) => {
  setTimeout(() => {
    try {
      const elms = data.map(item => {
        const queryId = `tab-${item.id}`;
        return frameDocument.getElementById(queryId);
      });
      setTabElmPosition(
        elms.map(elm => {
          return {
            x: elm ? elm.offsetLeft : 0,
            width: elm ? elm.offsetWidth : 0
          };
        })
      );
      // eslint-disable-next-line no-empty
    } catch (ex) {}
  }, 400);
};

export const Tabs = ({ onChange, data = [] }) => {
  const document = getDocument();

  const [tabActiveIndex, setActiveTabIndex] = React.useState(
    get(data, '[0].id', 0)
  );

  const [tabElmPosition, setTabElmPosition] = React.useState([]);

  const currentPosition = get(
    tabElmPosition,
    `[${data.findIndex(item => item.id === tabActiveIndex)}]`,
    {
      x: 0,
      width: 0
    }
  );

  React.useEffect(() => {
    calculatePosition({ data, setTabElmPosition, frameDocument: document });
  }, [data, document]);

  return (
    <MainTabs className="tabs">
      <div className="tab-header">
        {data.map(item => {
          const { id, displayName, icon } = item;
          const isActive = tabActiveIndex === id;
          return (
            <Tab
              id={`tab-${id}`}
              className={cl('tab', { active: isActive })}
              onClick={() => {
                setActiveTabIndex(id);
                onChange(id);
              }}
              key={id}
            >
              <Typography
                className="tab-header-text"
                style={{
                  color: isActive ? colorsV2.black100 : colorsV2.gray100
                }}
                variant="semi-bold/16"
              >
                <TabIcon icon={icon} />
                {displayName}
              </Typography>
            </Tab>
          );
        })}
      </div>
      <motion.div
        animate={{
          transition: {
            type: 'tween'
          },
          x: currentPosition.x,
          width: currentPosition.width + 4
        }}
        className="tab-indicator"
      />
    </MainTabs>
  );
};
