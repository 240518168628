import * as React from 'react';

import styled from 'styled-components';

import { Typography } from 'components/DesignSystemV2/Typography';

import { useBookData } from '../useBookData';

const Main = styled.div`
  b {
    font-weight: 600;
  }
`;

const TabProductDescription = () => {
  const { longDescription } = useBookData();
  return (
    <Main className="tab-product-description">
      <Typography variant="regular/14">
        <div
          dangerouslySetInnerHTML={{
            __html: longDescription
          }}
        />
      </Typography>
    </Main>
  );
};

export default TabProductDescription;
