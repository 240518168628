import * as React from 'react';

function IconFacebook(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M5.615 12.133L5.6 7.467H3.733V5.6H5.6V4.433c0-1.732 1.073-2.566 2.618-2.566.74 0 1.376.055 1.561.08v1.81H8.708c-.84 0-1.003.4-1.003.985V5.6h2.445l-.933 1.867H7.705v4.666h-2.09z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoIconFacebook = React.memo(IconFacebook);
export default MemoIconFacebook;
