/* eslint-disable react/prop-types */
import 'react-image-gallery/styles/css/image-gallery.css';

import * as React from 'react';
import ImageGallery from 'react-image-gallery';

import cl from 'classnames';
import { useKeenSlider } from 'keen-slider/react';
import { noop, take } from 'lodash';
import styled from 'styled-components';

import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { getAssetLinkByHandle } from 'utils/getAssetLink';
import { deviceBreakpoints } from 'utils/hooks/useDeviceQuery.hook';

import Modal from '../../CourseLanding/Modal';
import IconSchoolLearn from '../icons/IconSchoolLearn';
import { useBookData } from '../useBookData';

const Main = styled.div`
  .product-image-preview-wrapper {
    display: grid;
    gap: 12px;
  }
  .carousel-slider {
    border-radius: 8px;
  }
  .product-thumb {
    display: none;
    .thumb-wrapper {
      display: grid;
      grid-auto-flow: column;
      gap: 12px;

      .image-product {
        position: relative;
        border-radius: 8px;
        cursor: pointer;

        &.active {
          border: 1px solid ${colorsV2.primary100};
        }
      }

      .see-more-image {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 2;
        display: grid;
        place-items: center;
        color: ${colorsV2.white100};
        text-align: center;
        padding: 4px;
        cursor: pointer;
      }
    }
  }
  .product-preview {
    position: relative;
    .image-actions {
      .btn {
        cursor: pointer;
        z-index: 100;
        color: ${colorsV2.white100};
        padding: 4px 12px;
        background: rgba(36, 41, 56, 0.6);
        border-radius: 8px;
        transition: 0.2s all;
        &:active {
          transform: scale(0.98);
        }
        &.status {
          position: absolute;
          bottom: 12px;
          right: 12px;
        }
        &.view-detail {
          position: absolute;
          bottom: 12px;
          left: 12px;

          display: grid;
          grid-auto-flow: column;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }

  .image-product {
    overflow: hidden;
    border-radius: 4px;

    .wrapper {
      width: 100%;
      padding-top: 100%;

      height: 100%;

      position: relative;
      background-color: #c4c4c4;

      > * {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  @media ${deviceBreakpoints.md} {
    grid-auto-flow: row;
    gap: 18px;
    .product-thumb {
      display: grid;
      order: 2;
      grid-auto-flow: column;
    }

    .product-preview {
      .image-actions {
        .btn {
          &.status {
            display: none;
          }
        }
      }
    }
  }

  @media ${deviceBreakpoints.lg} {
    grid-auto-flow: row;
    gap: 18px;
    .product-image-preview-wrapper {
      grid-auto-flow: column;
      grid-template-columns: max-content;
    }

    .product-preview {
      order: 2;
    }
    .product-thumb {
      width: 72px;
      order: 1;
      .thumb-wrapper {
        grid-auto-flow: row;
      }
    }
  }
`;

const ImageGalleryContainer = styled.div`
  .image-gallery-content {
    .image-gallery-slide {
      .image-gallery-image {
        max-height: calc(80vh - 80px);
      }
    }
  }
`;

const MAX_THUMB_IMAGE = 4;
const MAIN_IMAGE_WIDTH = 900;
const THUMBNAIL_IMAGE_WIDTH = 80;

const ImageList = ({
  data,
  onImageClick,
  activeIndex,
  onSeeMoreImageClick = noop
}) => {
  const list = take(data, MAX_THUMB_IMAGE);
  return (
    <div>
      {list.map((image, imageIndex) => {
        const { id, handle } = image;
        return (
          <div
            key={id + imageIndex}
            onClick={() => {
              onImageClick(image, imageIndex);
            }}
            className={cl('image-product', {
              active: activeIndex === imageIndex
            })}
          >
            {data.length > MAX_THUMB_IMAGE &&
              imageIndex === MAX_THUMB_IMAGE - 1 && (
                <Typography
                  onClick={() => {
                    onSeeMoreImageClick();
                  }}
                  className="see-more-image"
                  variant="regular/10"
                >
                  Xem thêm +{data.length - MAX_THUMB_IMAGE} hình ảnh
                </Typography>
              )}
            <div className="wrapper">
              <img
                src={getAssetLinkByHandle({
                  handle,
                  width: THUMBNAIL_IMAGE_WIDTH
                })}
                loading={imageIndex === 0 ? 'eager' : 'lazy'}
                alt="lazy-img"
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ProductImagePreview = ({ onViewPdfClick }) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const { images } = useBookData();
  const [imagesLoaded, setImageLoaded] = React.useState([true]);

  const [isOpen, setOpen] = React.useState(false);

  const [sliderRef, slider] = useKeenSlider({
    move: instance => setActiveIndex(instance.details().relativeSlide),
    slidesPerView: 1,
    spacing: 24,
    breakpoints: {
      '(min-width: 768px)': {
        controls: false
      }
    }
  });

  React.useEffect(() => {
    const newLoaded = [...imagesLoaded];
    newLoaded[activeIndex] = true;
    setImageLoaded(newLoaded);
  }, [activeIndex, imagesLoaded]);

  return (
    <Main className="product-image-preview">
      <div className="product-image-preview-wrapper">
        <div className="product-thumb">
          <div className="thumb-wrapper">
            <ImageList
              activeIndex={activeIndex}
              data={images}
              onImageClick={(_, index) => {
                setActiveIndex(index);
                slider.moveToSlideRelative(index);
              }}
              onSeeMoreImageClick={() => {
                setActiveIndex(MAX_THUMB_IMAGE);
                setOpen(true);
              }}
            />
          </div>
        </div>
        <div className="product-preview">
          <div ref={sliderRef} className="keen-slider">
            {images.map((image, index) => {
              const { id, handle } = image;
              return (
                <div
                  key={id}
                  className={cl(
                    'keen-slider__slide',
                    'lazy__slide',
                    'image-product'
                  )}
                >
                  <div className="wrapper">
                    <img
                      src={
                        imagesLoaded[index]
                          ? getAssetLinkByHandle({
                              handle,
                              width: MAIN_IMAGE_WIDTH
                            })
                          : ''
                      }
                      alt="image-product"
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <Typography variant="semi-bold/14">
            <div className="image-actions">
              <div onClick={onViewPdfClick} className="btn view-detail">
                <IconSchoolLearn />
                Đọc thử
              </div>
              <div className="btn status">
                {activeIndex + 1}/{images.length}
              </div>
            </div>
          </Typography>
        </div>
      </div>

      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          setActiveIndex(0);
          setOpen(false);
        }}
      >
        <ImageGalleryContainer>
          <ImageGallery
            showPlayButton={false}
            showIndex
            lazyLoad
            items={images.map(image => {
              const { handle } = image;
              return {
                original: getAssetLinkByHandle({
                  handle,
                  width: MAIN_IMAGE_WIDTH
                }),
                thumbnail: getAssetLinkByHandle({
                  handle,
                  width: THUMBNAIL_IMAGE_WIDTH
                })
              };
            })}
          />
        </ImageGalleryContainer>
      </Modal>
    </Main>
  );
};

export default ProductImagePreview;
