import * as React from 'react';

function IconSchoolLearn(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M10 4.167a5.304 5.304 0 00-6.823-.573A1.652 1.652 0 002.5 4.942v10.796c0 .645.688 1.02 1.257.716a5.304 5.304 0 016.243.933V4.167a5.304 5.304 0 016.823-.573c.432.308.677.818.677 1.348v10.796c0 .645-.688 1.02-1.257.716a5.304 5.304 0 00-6.243.933"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoIconSchoolLearn = React.memo(IconSchoolLearn);
export default MemoIconSchoolLearn;
