import * as React from 'react';
import { FacebookProvider, ShareButton } from 'react-facebook';
import { Document, Page, pdfjs } from 'react-pdf';

import { Link } from 'gatsby';
import { meanBy } from 'lodash';
import numeral from 'numeral';
import { getWindow } from 'ssr-window';
import styled from 'styled-components';

import { Typography } from 'components/DesignSystemV2/Typography';
import { Rating } from 'shared/CourseLanding/Courses';
import Modal from 'shared/CourseLanding/Modal';
import { colorsV2 } from 'style/colors-v2';
import { getAssetLink } from 'utils/getAssetLink';
import {
  deviceBreakpoints,
  useDeviceDetect
} from 'utils/hooks/useDeviceQuery.hook';

import c from '../../constant';
import ProductImagePreview from './components/ProductImagePreview';
import { useBookReviews } from './components/TabUserReviews';
import IconFacebook from './icons/IconFacebook';
import { useBookData } from './useBookData';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

/* eslint-disable react/prop-types */
const Main = styled.section`
  display: grid;
  gap: 16px;
  padding-top: 24px;

  a {
    text-decoration: underline;
    color: ${colorsV2.primary100};
  }

  #image-magnifier {
    top: -5%;
    position: absolute;
    z-index: 1000;
    img {
      max-width: initial;
    }
  }

  .share-button {
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    font-size: 0;
    line-height: 0;
  }
  .info-container {
    display: grid;
    gap: 16px;

    .info-header-container {
      display: grid;
      gap: 4px;
      .extra-info {
        display: grid;
        gap: 4px;
        .brand-upc,
        .rating-share {
          display: grid;
          gap: 8px;
          grid-template-columns: max-content max-content max-content;
        }
      }
    }

    .price-container {
      display: grid;
      gap: 8px;
      .price-with-discount-container {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: max-content;
        gap: 20px;
        .price-original {
          text-decoration: line-through;
        }
      }
    }

    .info-footer-container {
      display: grid;
      gap: 12px;
    }
    .alert {
      padding: 12px;
      background-color: ${colorsV2.primary10};
      border: 1px solid ${colorsV2.primary40};
      border-radius: 4px;
    }
  }

  button.btn-fb-share {
    > .button-wrapper {
      height: 20px;
      display: grid;
      gap: 2px;
      align-items: center;
      grid-auto-flow: column;
    }
    .icon {
      line-height: 0;
      font-size: 14px;
    }
    background: #2074bb;
    border-radius: 4px;
    padding: 0px 8px 0px 4px;
    border: none;
    cursor: pointer;
    transition: 0.2s all;
    &:active {
      transform: scale(0.98);
    }
  }

  .vertical-separator {
    width: 1px;
    height: 100%;
    background-color: ${colorsV2.gray40};
  }

  .horizontal-separator {
    height: 1px;
    width: 100%;
    background-color: ${colorsV2.gray40};
  }

  @media ${deviceBreakpoints.md} {
    grid-template-columns: 324px 1fr;
    gap: 24px;
    .info-container {
      gap: 24px;
      .info-header-container {
        gap: 8px;
      }
      .price-container {
        display: grid;
        gap: 8px;
        grid-auto-flow: column;
        .u-have-discount {
          text-align: center;
        }
      }
    }
  }

  @media ${deviceBreakpoints.lg} {
    grid-template-columns: 426px 1fr;
    .info-container {
      .info-header-container {
        .extra-info {
          gap: 12px;
          grid-template-columns: repeat(auto-fit, minmax(0, max-content));
          grid-auto-flow: column;
          .brand-upc,
          .rating-share {
            gap: 12px;
          }
        }
      }
      .price-container {
        gap: 20px;
        align-items: center;
        grid-template-columns: repeat(auto-fit, minmax(0, max-content));
        .price-with-discount-container {
          align-items: center;
        }
      }
    }
  }
`;

const PdfViewerContainer = styled.div`
  max-height: 85vh;
  width: calc(100vw - 48px);
  overflow: auto;
  @media ${deviceBreakpoints.md} {
    max-height: 80vh;
    width: calc(680px - 48px);
  }
  @media ${deviceBreakpoints.lg} {
    width: 1000px;
  }
`;
const BuyBtn = styled.button`
  border: none;
  background: #2074bb;
  border-radius: 4px;
  padding: 12px 32px;
  cursor: pointer;
  transition: 0.2s all;
  &:active {
    transform: scale(0.98);
  }
`;

const BuyFromTikiBtn = ({ ctaLink }) => (
  <Link target="_blank" rel="noreferrer noopener" to={ctaLink}>
    <BuyBtn className="btn-buy">
      <Typography
        variant="semi-bold/16"
        className="font-noto"
        style={{ color: colorsV2.white100 }}
      >
        Mua ngay qua TIKI
      </Typography>
    </BuyBtn>
  </Link>
);

const BookProductContent = () => {
  const {
    originalPrice,
    discountedPrice,
    ctaLink,
    title,
    brand,
    productId,
    description,
    previewBookPdfHandle,
    promotionText
  } = useBookData();

  const documentWrapperRef = React.useRef();
  const [showModal, setShowModal] = React.useState(false);
  const bookReviews = useBookReviews();
  const window = getWindow();

  const pdfLink = `${getAssetLink({
    item: { handle: previewBookPdfHandle }
  })}`;

  const [numPages, setNumPages] = React.useState(null);

  const avgStars = meanBy(bookReviews, item => {
    return Number(item.rating) || 5;
  });

  const { isMobile, isTablet } = useDeviceDetect();
  const pdfPageWidth = React.useMemo(() => {
    if (isMobile) {
      return window.innerWidth - 48;
    }
    if (isTablet) {
      return 680 - 48;
    }
    return 1000;
  }, [isMobile, isTablet, window.innerWidth]);

  return (
    <Main className="book-selling-product-content">
      <ProductImagePreview
        onViewPdfClick={() => {
          setShowModal(true);
        }}
      />
      <div className="info-container">
        <div id="image-magnifier" />
        <div className="info-header-container">
          <Typography variant="semi-bold/16" tabletVariant="semi-bold/24">
            {title}
          </Typography>
          <div className="extra-info">
            <div className="brand-upc">
              <Typography
                variant="regular/14"
                style={{ color: colorsV2.black60 }}
              >
                Thương hiệu: {brand}
              </Typography>
              <div className="vertical-separator" />
              <Typography
                variant="regular/14"
                style={{ color: colorsV2.black60 }}
              >
                Mã SP: {productId}
              </Typography>
            </div>
            <div className="vertical-separator" />

            <div className="rating-share">
              <Rating stars={avgStars} rated={bookReviews.length} />
              <div className="vertical-separator" />
              <FacebookProvider appId={c.FACEBOOK_APP_ID}>
                <ShareButton
                  className="share-button"
                  href={window.location.href}
                >
                  <button className="btn-fb-share">
                    <Typography
                      className="button-wrapper"
                      style={{ color: colorsV2.white100 }}
                      variant="semi-bold/12"
                    >
                      <span className="icon">
                        <IconFacebook />
                      </span>
                      Chia sẻ
                    </Typography>
                  </button>
                </ShareButton>
              </FacebookProvider>
            </div>
          </div>
        </div>

        <div className="horizontal-separator" />

        <div className="price-container">
          <div className="price-with-discount-container">
            <Typography
              className="price-discounted"
              style={{ color: colorsV2.primary100 }}
              variant="semi-bold/24"
              tabletVariant="semi-bold/32"
            >
              {numeral(discountedPrice).format('0,0')}₫
            </Typography>
            <Typography
              className="price-original"
              style={{ color: colorsV2.black60 }}
              variant="regular/16"
              tabletVariant="regular/20"
            >
              {numeral(originalPrice).format('0,0')}₫
            </Typography>
          </div>
          <Typography className="u-have-discount" variant="regular/14">
            (Bạn đã tiết kiệm được{' '}
            {numeral(originalPrice - discountedPrice).format('0,0')}₫)
          </Typography>
          <div className="mobile-only">
            <BuyFromTikiBtn ctaLink={ctaLink} />
          </div>
        </div>

        <div className="horizontal-separator" />

        <Typography type="span" variant="regular/14">
          <div
            dangerouslySetInnerHTML={{
              __html: description
            }}
          />
        </Typography>

        <div className="horizontal-separator" />

        <div className="info-footer-container">
          <Typography type="span" variant="regular/14">
            <div className="alert">
              <div
                dangerouslySetInnerHTML={{
                  __html: promotionText
                }}
              />
            </div>
          </Typography>
          <BuyFromTikiBtn ctaLink={ctaLink} />
        </div>
      </div>

      <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <PdfViewerContainer ref={documentWrapperRef}>
          <Document
            file={pdfLink}
            onLoadSuccess={({ numPages }) => {
              setNumPages(numPages);
            }}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                width={pdfPageWidth}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />
            ))}
          </Document>
        </PdfViewerContainer>
      </Modal>
    </Main>
  );
};

export default BookProductContent;
