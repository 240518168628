import * as React from 'react';

import { Link } from 'gatsby';
import styled from 'styled-components';

/* eslint-disable react/prop-types */
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { deviceBreakpoints } from 'utils/hooks/useDeviceQuery.hook';

import { useBookData } from './useBookData';

const Main = styled.section`
  background-color: ${colorsV2.blue100};
  display: grid;
  place-items: center;
  padding: 32px;

  .footer-content {
    display: grid;
    place-items: center;
    text-align: center;
    gap: 16px;
    button {
      background: #ffffff;
      border-radius: 4px;
      padding: 12px 32px;
      border: none;
      transition: 0.2s all;
      cursor: pointer;
      &:active {
        transform: scale(0.98);
      }
    }
  }

  @media ${deviceBreakpoints.md} {
    .footer-content {
      grid-auto-flow: column;
    }
  }
`;

const BookProductFooter = () => {
  const { ctaLink } = useBookData();
  return (
    <Main className="book-selling-footer">
      <div className="footer-content">
        <Typography style={{ color: colorsV2.white100 }} variant="semi-bold/16">
          Thông não IELTS Reading cùng Linearthinking
        </Typography>
        <Link target="_blank" rel="noreferrer noopener" to={ctaLink}>
          <button>
            <Typography variant="semi-bold/16">Mua ngay qua TIKI</Typography>
          </button>
        </Link>
      </div>
    </Main>
  );
};

export default BookProductFooter;
