import * as React from 'react';

function IconSmile(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 17.333A8.333 8.333 0 109 .667a8.333 8.333 0 000 16.666zm0-1.666A6.667 6.667 0 109 2.333a6.667 6.667 0 000 13.334zm-2.5-7.5a.833.833 0 100-1.667.833.833 0 000 1.667zm4.167 1.666h1.666a3.333 3.333 0 01-6.666 0h1.666a1.667 1.667 0 103.334 0zm.833-1.666a.833.833 0 100-1.667.833.833 0 000 1.667z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoIconSmile = React.memo(IconSmile);
export default MemoIconSmile;
