/* eslint-disable react/prop-types */
import * as React from 'react';

import cl from 'classnames';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import { colorsV2 } from 'style/colors-v2';

import { Tabs } from './components/Tab';
import TabProductDescription from './components/TabProductDescription';
import TabUserReviews from './components/TabUserReviews';

const tabPanelVariant = {
  show: {
    y: 0,
    zIndex: 1,
    opacity: 1
  },
  hidden: {
    zIndex: 0,
    opacity: 0
  }
};

const Main = styled.section`
  padding-top: 24px;
  .tab-panel-wrapper {
    padding-top: 32px;
    position: relative;
  }
  .tab-panel-content {
  }

  .card-user-review-list {
    display: grid;
    gap: 16px;
  }
  .horizontal-separator {
    height: 1px;
    width: 100%;
    background-color: ${colorsV2.gray40};
  }
`;

const TABS = [
  { id: 'mo-ta-san-pham', displayName: 'Mô tả sản phẩm', icon: 'info' },
  { id: 'danh-gia', displayName: 'Đánh giá', icon: 'smile' }
];

const TabPanel = ({ tabIndex, tabId, children }) => {
  const isShowing = tabIndex === tabId;
  return (
    isShowing && (
      <motion.div
        key={tabId}
        variants={tabPanelVariant}
        initial="hidden"
        animate="show"
        className={cl('tab-panel-content', {
          showing: isShowing
        })}
      >
        {children}
      </motion.div>
    )
  );
};

const BookProductDescription = () => {
  const [tabIndex, setTabIndex] = React.useState(TABS[0].id);

  return (
    <Main className="book-selling-description">
      <Tabs onChange={setTabIndex} data={TABS}></Tabs>

      <div className="tab-panel-wrapper">
        <TabPanel tabIndex={tabIndex} tabId={TABS[0].id}>
          <TabProductDescription />
        </TabPanel>
        <TabPanel tabIndex={tabIndex} tabId={TABS[1].id}>
          <TabUserReviews />
        </TabPanel>
      </div>
    </Main>
  );
};

export default BookProductDescription;
